@import "../../assets/styles/parts";

:root {
  .MenuIcon {
    width: 32px;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    margin-left: 24px;
    &_Line {
      width: 100%;
      height: 4px;
      border-radius: 1px;
      transition: background 0.25s step-end;
      @extend %main-gradient;
    }

    &-isActive {
      .MenuIcon_Line {
        transition: background 0.25s step-end;

        &:first-of-type,
        &:last-of-type {
          background-image: linear-gradient(#446475, #446475);
        }
      }
    }
  }
}
