@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Cambay:400,400i,700,700i);
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #263842; }

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none; }

.image {
  position: relative;
  margin-bottom: 1.5em;
  border-radius: 7px;
  border: 1px solid #eee;
  overflow: hidden; }
  .image img {
    width: 100%; }


:root .Search_Button {
  background: linear-gradient(119deg, #12abae, #88c69a); }

.Wrapper {
  width: 100%;
  max-width: 1350px;
  padding: 0 15px;
  min-width: 1180px;
  margin: 0 auto; }

.Btn {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.1;
  background: transparent;
  border: 0;
  position: relative;
  cursor: pointer;
  padding: 11px 15px;
  outline: none;
  font-family: Roboto; }
  .Btn.Btn-Green span {
    color: #fff; }
  .Btn.Btn-Green:before {
    content: none; }
  .Btn[disabled] {
    cursor: no-drop; }
    .Btn[disabled] span {
      color: #b7c2c9; }
    .Btn[disabled]:after, .Btn[disabled]:before {
      background: #d8e4ea; }
  .Btn:before, .Btn:after {
    transition: all 0.3s;
    content: '';
    position: absolute;
    display: block;
    border-radius: 3px; }
  .Btn:after {
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(105deg, #12abae, #88c69a); }
  .Btn:before {
    background: #fff;
    left: 1px;
    right: 1px;
    bottom: 1px;
    top: 1px;
    z-index: 2; }
  .Btn span {
    position: relative;
    z-index: 3;
    color: #7ecfa9; }
  .Btn-Text {
    padding: 0;
    background: transparent;
    border: 0;
    position: relative;
    cursor: pointer;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.1;
    color: #7ecfa9; }
  .Btn.WithIcon {
    padding-left: 48px; }
    .Btn.WithIcon .WithIcon-Img {
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translateY(-50%); }
      .Btn.WithIcon .WithIcon-Img svg {
        display: block; }

:root .Search {
  position: relative; }
  :root .Search_Input {
    width: 100%;
    padding: 11px 120px 11px 12px;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    box-sizing: border-box; }
  :root .Search_Button {
    position: absolute;
    right: 5rem;
    top: 0;
    width: 70px;
    height: 100%;
    border: 0;
    outline: none;
    cursor: pointer;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
    :root .Search_Button-isFilter {
      right: 9rem;
      background: transparent; }
    :root .Search_Button img {
      opacity: 0.5; }
  :root .Search-Badge {
    position: absolute;
    height: 8px;
    width: 8px;
    top: 6px;
    right: 22px;
    background: red;
    border-radius: 50%;
    pointer-events: none;
    z-index: 10; }

:root .MenuIcon_Line {
  background: linear-gradient(119deg, #12abae, #88c69a); }

.Wrapper {
  width: 100%;
  max-width: 1350px;
  padding: 0 15px;
  min-width: 1180px;
  margin: 0 auto; }

.Btn {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.1;
  background: transparent;
  border: 0;
  position: relative;
  cursor: pointer;
  padding: 11px 15px;
  outline: none;
  font-family: Roboto; }
  .Btn.Btn-Green span {
    color: #fff; }
  .Btn.Btn-Green:before {
    content: none; }
  .Btn[disabled] {
    cursor: no-drop; }
    .Btn[disabled] span {
      color: #b7c2c9; }
    .Btn[disabled]:after, .Btn[disabled]:before {
      background: #d8e4ea; }
  .Btn:before, .Btn:after {
    transition: all 0.3s;
    content: '';
    position: absolute;
    display: block;
    border-radius: 3px; }
  .Btn:after {
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(105deg, #12abae, #88c69a); }
  .Btn:before {
    background: #fff;
    left: 1px;
    right: 1px;
    bottom: 1px;
    top: 1px;
    z-index: 2; }
  .Btn span {
    position: relative;
    z-index: 3;
    color: #7ecfa9; }
  .Btn-Text {
    padding: 0;
    background: transparent;
    border: 0;
    position: relative;
    cursor: pointer;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.1;
    color: #7ecfa9; }
  .Btn.WithIcon {
    padding-left: 48px; }
    .Btn.WithIcon .WithIcon-Img {
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translateY(-50%); }
      .Btn.WithIcon .WithIcon-Img svg {
        display: block; }

:root .MenuIcon {
  width: 32px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 24px; }
  :root .MenuIcon_Line {
    width: 100%;
    height: 4px;
    border-radius: 1px;
    transition: background 0.25s step-end; }
  :root .MenuIcon-isActive .MenuIcon_Line {
    transition: background 0.25s step-end; }
    :root .MenuIcon-isActive .MenuIcon_Line:first-of-type, :root .MenuIcon-isActive .MenuIcon_Line:last-of-type {
      background-image: linear-gradient(#446475, #446475); }

:root .User {
  margin-top: -7px;
  font-size: 14px; }
  :root .User_Icon {
    margin-right: 0.5em;
    transform: translateY(25%); }
  :root .User_Position {
    color: #8a97a0;
    margin-left: 10px; }

:root .Notification {
  padding: 0 0 0 16px;
  position: relative; }
  :root .Notification_counter:after {
    content: attr(data-count);
    position: absolute;
    height: 16px;
    width: 16px;
    line-height: 16px;
    top: -0.9em;
    right: -10px;
    background: red;
    border-radius: 50%;
    text-align: center;
    font-size: 10px; }

:root .MainMenu {
  display: flex;
  justify-items: stretch;
  border-top: 1px solid #446475;
  color: #8a97a0;
  font-size: 16px;
  margin: 0 auto;
  max-width: 1360px;
  transition: all 0.2s;
  overflow: hidden;
  -webkit-animation: menu-visible 0.3s;
          animation: menu-visible 0.3s; }
  :root .MainMenu_Item {
    flex: 1 1 100%;
    height: 112px;
    text-align: center;
    padding: 16px 5px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
  :root .MainMenu .MenuItem-isActive {
    background-color: #446475; }
    :root .MainMenu .MenuItem-isActive svg path {
      fill: white !important; }
    :root .MainMenu .MenuItem-isActive .MenuItem_Text {
      color: white; }
  :root .MainMenu .MenuItem_Image {
    cursor: pointer;
    width: 100%;
    max-width: 52px;
    margin: 0 auto;
    display: inline-block; }
    :root .MainMenu .MenuItem_Image svg {
      display: inline-block;
      vertical-align: middle; }
  :root .MainMenu .MenuItem_Text {
    margin-top: 9px; }

@-webkit-keyframes menu-visible {
  from {
    opacity: 0;
    min-height: 0;
    height: 0; }
  to {
    min-height: 15px;
    height: auto; } }

@keyframes menu-visible {
  from {
    opacity: 0;
    min-height: 0;
    height: 0; }
  to {
    min-height: 15px;
    height: auto; } }

:root .Select {
  margin-bottom: 24px; }
  :root .Select_width {
    width: 246px; }
  :root .Select.Select_Error .react-select__control {
    border-color: #e83323; }
  :root .Select_Label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 8px;
    letter-spacing: 0.5px; }
  :root .Select_FakeBlock {
    display: flex;
    align-items: center;
    min-height: 38px;
    padding-left: 10px;
    border-radius: 3px;
    border: solid 1px #d8e4ea;
    background-color: #edf2f5;
    font-size: 14px;
    line-height: 1.71;
    color: #b7c2c9;
    position: relative; }
  :root .Select_InfoImg {
    width: 16px;
    height: 16px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%); }
  :root .Select_Tooltip {
    max-width: 136px;
    box-shadow: 0 2px 6px 0 rgba(38, 56, 66, 0.2);
    padding: 12px;
    background-color: #263842;
    line-height: normal;
    text-align: center; }
    :root .Select_Tooltip:after {
      border-top-color: #263842 !important; }

:root .react-select__indicator-separator {
  display: none; }

:root .react-select__placeholder {
  font-size: 14px;
  color: #b7c2c9;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 98%; }

:root .react-select__single-value {
  font-size: 14px; }

:root .react-select__control {
  cursor: pointer; }
  :root .react-select__control svg {
    fill: #7ecfa9; }

:root .SelectRange {
  margin-bottom: 24px; }
  :root .SelectRange_Label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 8px;
    letter-spacing: 0.5px; }
  :root .SelectRange_Sections {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  :root .SelectRange_Separator {
    width: 1em;
    height: 1px;
    background-color: #d8e4ea;
    margin: 0 5px;
    flex: 0 0 1em; }
  :root .SelectRange_Section {
    flex: 1 1 auto; }

:root .RadioGroup {
  margin-bottom: 24px;
  cursor: pointer; }
  :root .RadioGroup_Header {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px; }
  :root .RadioGroup_Field {
    display: inline-flex; }
    :root .RadioGroup_Field ~ .RadioGroup_Field {
      margin-left: 40px; }
    :root .RadioGroup_Field.active .RadioGroup_box {
      position: relative; }
      :root .RadioGroup_Field.active .RadioGroup_box:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-image: linear-gradient(135deg, #12abae, #88c69a); }
  :root .RadioGroup_box {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 2px #b7c2c9;
    background-color: #ffffff;
    display: inline-block; }
  :root .RadioGroup_Label {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: inline-block;
    font-size: 14px;
    height: 24px;
    line-height: 26px;
    cursor: pointer;
    position: relative;
    margin-left: 8px; }
    :root .RadioGroup_Label:first-letter {
      text-transform: capitalize; }

.spinner {
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  padding-top: 100px; }
  .spinner__small {
    width: 20px;
    height: 20px; }
    .spinner__small:after {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      margin: 1px;
      border-radius: 50%;
      border: 2px solid #fff;
      border-color: #1faeac transparent #1faeac transparent;
      -webkit-animation: lds-dual-ring 1.2s linear infinite;
              animation: lds-dual-ring 1.2s linear infinite; }

.spinner:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #1faeac transparent #1faeac transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite; }

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

:root .FilterForm_Groups {
  display: flex;
  justify-content: space-between; }

:root .FilterForm_Group {
  flex: 1 1 100%;
  margin-bottom: 8px; }
  :root .FilterForm_Group .RadioGroup_Header {
    margin-bottom: 16px; }

:root .FilterForm .FilterForm_Group ~ .FilterForm_Group {
  margin-left: 2.5rem; }

:root .FilterForm_Button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding: 0; }

:root .FilterForm_cancel {
  color: #8a97a0;
  margin-right: 32px; }

:root .FilterForm_submit {
  color: #7ecfa9; }

:root .FilterForm_disabled {
  color: #8a97a0;
  cursor: not-allowed; }

:root .FilterForm_clear {
  font-size: 14px;
  color: #d0021b;
  cursor: pointer; }

:root .Actions {
  display: flex;
  align-items: center;
  justify-content: space-between; }

:root .SearchFilter {
  position: absolute;
  top: 72px;
  padding: 20px 8%;
  width: 100%;
  color: black;
  background-color: white;
  z-index: 999;
  box-shadow: 0 4px 14px 0 rgba(138, 151, 160, 0.3);
  box-sizing: border-box; }
  :root .SearchFilter_Caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    width: 100%; }
  :root .SearchFilter_Header {
    font-size: 14px;
    color: #263842;
    font-weight: 500; }
  :root .SearchFilter_Close {
    border: none;
    background-color: transparent;
    cursor: pointer; }
    :root .SearchFilter_Close img {
      width: 20px;
      height: 20px; }

:root .Header {
  font-size: 12px;
  color: white;
  background-color: #263842;
  transition: all 0.3s;
  position: relative; }
  :root .Header_Caption {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 72px;
    max-width: 1360px;
    margin: 0 auto; }
  :root .Header.Header-isOpen {
    flex: 0 0 184px; }
  :root .Header.Header-isFilterOpen {
    position: relative; }
  :root .Header .Logo {
    float: left; }
    :root .Header .Logo_Slogan {
      margin: 4px 15px 0;
      width: 12em;
      float: right; }
  :root .Header_Search {
    flex: 1 1 30%;
    padding: 0 5rem; }

.notification-success {
  position: absolute;
  top: 80px;
  left: 0;
  height: 50px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: white;
  line-height: 50px;
  background-color: rgba(6, 176, 133, 0.85); }
  .notification-success button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    width: 16px;
    height: 16px; }
  .notification-success img {
    width: 13px;
    height: 13px; }

.Popup {
  position: fixed;
  z-index: 150;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0; }
  .Popup-Bg {
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.75;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, #121b21, #121b21); }
  .Popup-Close {
    position: absolute;
    z-index: 6;
    background: transparent;
    padding: 0;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    border: 0;
    min-width: 1px;
    outline: none;
    cursor: pointer; }
    .Popup-Close:before, .Popup-Close:after {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 2px;
      height: 24px;
      margin-top: -12px;
      margin-left: -1px;
      background-image: linear-gradient(103deg, #12abae, #88c69a); }
    .Popup-Close:after {
      transform: rotate(45deg); }
    .Popup-Close:before {
      transform: rotate(-45deg); }
  .Popup-Wrapper {
    height: 100vh;
    overflow: auto; }
  .Popup-Content {
    display: table;
    height: 100%;
    max-width: 475px;
    width: 100%;
    margin: 0 auto; }
  .Popup-Inner {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 0; }
  .Popup-Block {
    position: relative;
    z-index: 10;
    border-radius: 3px;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    padding: 63px 30px 50px;
    text-align: center; }
  .Popup-Icon {
    margin-bottom: 16px; }
    .Popup-Icon img {
      max-width: 100%; }
  .Popup-Title {
    font-family: Cambay;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 9px;
    color: #263842; }
  .Popup-Text {
    line-height: 1.5;
    color: #8a97a0; }
  .Popup-Bottom {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .Popup-Bottom.Center {
      justify-content: center; }
  .Popup-Btn {
    font-size: 14px;
    padding: 16px 15px;
    font-weight: 700;
    text-transform: uppercase;
    width: 48%; }
    .Popup-Btn.Btn {
      font-size: 14px;
      padding: 16px 15px; }
  .Popup-InfoArchive {
    max-width: 875px; }
    .Popup-InfoArchive-Content {
      position: relative;
      z-index: 5; }
    .Popup-InfoArchive-Count {
      text-align: center;
      position: relative;
      z-index: 5;
      font-size: 24px;
      line-height: 1;
      color: #edf2f5;
      padding-bottom: 30px; }
    .Popup-InfoArchive .Popup-Block {
      padding: 21px 25px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-radius: 0 0 3px 3px; }
    .Popup-InfoArchive-Img {
      border-radius: 3px 3px 0 0;
      width: 100%;
      height: 515px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: #fff; }
    .Popup-InfoArchive-Left {
      text-align: left;
      width: 60%; }
    .Popup-InfoArchive-Right {
      width: 40%;
      text-align: right; }
      .Popup-InfoArchive-Right .Btn {
        margin: 0 8px; }
        .Popup-InfoArchive-Right .Btn:last-child {
          margin-right: 0; }
    .Popup-InfoArchive-Title {
      font-family: Cambay;
      font-size: 32px;
      font-weight: normal;
      line-height: 1.1;
      color: #55b58a; }
    .Popup-InfoArchive-Item {
      padding: 10px 0 5px;
      line-height: 1.25;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-size: 14px; }
    .Popup-InfoArchive-Name {
      color: #8a97a0;
      padding: 10px 0; }
      .Popup-InfoArchive-Name span {
        color: #263842;
        padding-left: 15px; }
    .Popup-InfoArchive-Btn {
      outline: none;
      cursor: pointer;
      width: 56px;
      z-index: 6;
      height: 56px;
      padding: 0;
      position: absolute;
      top: 50%;
      margin-top: -28px;
      border: 1px solid #d8e4ea;
      background: transparent; }
      .Popup-InfoArchive-Btn:hover {
        border-style: solid;
        border-color: #55b58a;
        border-width: 1px;
        border-image-source: linear-gradient(135deg, #12abae, #88c69a);
        border-image-slice: 1;
        background: transparent; }
        .Popup-InfoArchive-Btn:hover svg path {
          fill: #55b58a; }
      .Popup-InfoArchive-Btn[disabled] {
        border: 1px solid #d8e4ea;
        background: transparent;
        cursor: no-drop; }
        .Popup-InfoArchive-Btn[disabled] svg path {
          fill: #d8e4ea; }
      .Popup-InfoArchive-Btn.Prev {
        left: -180px; }
      .Popup-InfoArchive-Btn.Next {
        right: -180px; }
        .Popup-InfoArchive-Btn.Next span {
          transform: rotate(180deg); }
      .Popup-InfoArchive-Btn span {
        display: block;
        width: 28px;
        margin: -14px 0 0 -14px;
        top: 50%;
        left: 50%;
        position: absolute; }
        .Popup-InfoArchive-Btn span svg {
          display: inline-block;
          vertical-align: middle; }
          .Popup-InfoArchive-Btn span svg path {
            fill: #d8e4ea; }

:root .Footer {
  background-color: #263842;
  letter-spacing: 0.5px;
  color: #8a97a0; }
  :root .Footer_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px; }

.row {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -40px;
  align-content: flex-start; }
  .row.row--centered {
    align-items: center;
    justify-content: center; }
  .row.row--full {
    align-items: stretch;
    align-content: stretch; }
  .row.row--ac {
    align-items: baseline; }
  .row.row--ab {
    flex-direction: row;
    align-items: flex-end; }
  .row.row--as-col {
    flex-direction: column; }
  .row.row--am {
    flex-direction: row;
    align-items: center; }
  .row.row--reverse {
    flex-direction: row-reverse; }
  .row.row--je {
    flex-direction: row;
    justify-content: flex-end; }
  .row.row--tr {
    text-align: right; }
  .row.row--sb {
    flex-direction: row;
    justify-content: space-between; }
  .row.row--no-wrap {
    flex-wrap: nowrap; }
  .row.row--wh-margin {
    margin-bottom: 2em; }

.col {
  box-sizing: border-box;
  min-height: 1px;
  padding-left: 40px;
  align-items: flex-start;
  flex: 1 1; }
  .col-tr {
    text-align: right; }
  .col--wh-margin {
    margin-bottom: 2em; }
  .col--first {
    order: -1; }
  .col--last {
    order: 5; }
  .col--order-1 {
    order: 1; }
  .col--order-2 {
    order: 2; }
  .col--order-3 {
    order: 3; }
  .col--order-4 {
    order: 4; }
  .col--auto {
    flex: 0 0 auto; }
  .col--center {
    align-self: center; }
  .col--bottom {
    align-self: flex-end; }

.col--5-of-5, .col--6-of-6, .col--8-of-8, .col--12-of-12 {
  flex: 0 1 100%; }

.col--3-of-6, .col--4-of-8, .col--6-of-12 {
  flex: 0 1 50%; }

.col--2-of-6, .col--4-of-12 {
  flex: 0 1 33.33333%; }

.col--4-of-6, .col--8-of-12 {
  flex: 0 1 66.66667%; }

.col--2-of-8, .col--3-of-12 {
  flex: 0 1 25%; }

.col--6-of-8, .col--9-of-12 {
  flex: 0 1 75%; }

.col--1-of-5 {
  flex: 0 1 20%; }

.col--2-of-5 {
  flex: 0 1 40%; }

.col--3-of-5 {
  flex: 0 1 60%; }

.col--4-of-5 {
  flex: 0 1 80%; }

.col--1-of-6 {
  flex: 0 1 16.66667%; }

.col--5-of-6 {
  flex: 0 1 83.33333%; }

.col--1-of-8 {
  flex: 0 1 12.5%; }

.col--3-of-8 {
  flex: 0 1 37.5%; }

.col--5-of-8 {
  flex: 0 1 62.5%; }

.col--7-of-8 {
  flex: 0 1 87.5%; }

.col--1-of-12 {
  flex: 0 1 8.33333%; }

.col--2-of-12 {
  flex: 0 1 16.66667%; }

.col--5-of-12 {
  flex: 0 1 41.66667%; }

.col--7-of-12 {
  flex: 0 1 58.33333%; }

.col--10-of-12 {
  flex: 0 1 83.33333%; }

.col--11-of-12 {
  flex: 0 1 91.66667%; }

.container {
  margin: 0 auto;
  width: 1360px;
  margin-top: 30px; }

.row--no-gutter {
  margin-left: 0; }
  .row--no-gutter > .col {
    padding-left: 0; }

.row--gutter-s {
  margin-left: -20px; }
  .row--gutter-s > .col {
    padding-left: 20px; }

.pull-text--right {
  text-align: right; }

.Button.Button-withBackground {
  background: linear-gradient(119deg, #12abae, #88c69a); }

.Button, .bb-block {
  border-image-source: linear-gradient(104deg, #12abae, #88c69a);
  border-image-slice: 1; }

.Wrapper {
  width: 100%;
  max-width: 1350px;
  padding: 0 15px;
  min-width: 1180px;
  margin: 0 auto; }

.Btn {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.1;
  background: transparent;
  border: 0;
  position: relative;
  cursor: pointer;
  padding: 11px 15px;
  outline: none;
  font-family: Roboto; }
  .Btn.Btn-Green span {
    color: #fff; }
  .Btn.Btn-Green:before {
    content: none; }
  .Btn[disabled] {
    cursor: no-drop; }
    .Btn[disabled] span {
      color: #b7c2c9; }
    .Btn[disabled]:after, .Btn[disabled]:before {
      background: #d8e4ea; }
  .Btn:before, .Btn:after {
    transition: all 0.3s;
    content: '';
    position: absolute;
    display: block;
    border-radius: 3px; }
  .Btn:after {
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(105deg, #12abae, #88c69a); }
  .Btn:before {
    background: #fff;
    left: 1px;
    right: 1px;
    bottom: 1px;
    top: 1px;
    z-index: 2; }
  .Btn span {
    position: relative;
    z-index: 3;
    color: #7ecfa9; }
  .Btn-Text {
    padding: 0;
    background: transparent;
    border: 0;
    position: relative;
    cursor: pointer;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.1;
    color: #7ecfa9; }
  .Btn.WithIcon {
    padding-left: 48px; }
    .Btn.WithIcon .WithIcon-Img {
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translateY(-50%); }
      .Btn.WithIcon .WithIcon-Img svg {
        display: block; }

* {
  box-sizing: border-box; }

.JTI_PWS_APP {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 500px;
  min-width: 1200px; }

.error-notification .ant-notification-notice-close {
  left: 28px;
  width: 24px;
  height: 24px;
  cursor: pointer; }
  .error-notification .ant-notification-notice-close * {
    display: none; }

.Header {
  flex: 0 0 72px; }

.Main {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column; }
  .Main_Content {
    flex: 1 1 auto; }

.Footer {
  flex: 0 0 64px; }
  .Footer_cont {
    max-width: 1360px;
    margin: 0 auto; }

.Button {
  height: 40px;
  padding: 0 16px;
  line-height: 40px;
  display: inline-block;
  color: #263842;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  cursor: pointer; }
  .Button-isWhite {
    background: transparent;
    border-color: white;
    color: white;
    border-image-source: none; }
  .Button-withoutBorder {
    border-image-source: none;
    border-color: transparent; }
  .Button.Button-withMarginRight {
    margin-right: 20px; }
  .Button.Button-withBackground {
    color: white; }
  .Button:disabled {
    background: #d8e4ea !important;
    color: #b7c2c9 !important; }

.Person_Id {
  color: #8a97a0;
  padding-right: 6px;
  font-size: 14px; }

.Person_Team {
  font-size: 14px;
  border: 1px solid #55b58a;
  border-radius: 15px;
  padding: 5px 13px;
  color: #55b58a;
  display: inline-block; }

.Person_Name {
  font-size: 32px;
  color: #55b58a; }

.Person_Supervisor {
  color: #55b58a; }

.Person_placeIcon {
  width: 24px;
  margin-right: 10px; }

.Person_geolocation {
  display: inline-flex;
  align-items: center;
  margin: 16px 0; }
  .Person_geolocation span {
    color: #8a97a0; }

.Link {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .Link-withColor, .Link-withColor:visited, .Link-withColor:active, .Link-withColor:hover {
    color: #7ecfa9; }
  .Link-withIcon {
    display: inline-flex;
    line-height: 24px; }
    .Link-withIcon:not(:last-child):after {
      content: '';
      height: 24px;
      width: 1px;
      background-color: #59bba1;
      margin-left: 16px;
      margin-right: 16px; }

.with-shadow {
  padding-bottom: 16px;
  box-shadow: 0 3px 3px 0 rgba(68, 100, 117, 0.18); }

.bb-block {
  height: auto;
  padding-bottom: 1em;
  border-bottom-width: 2px;
  border-bottom-style: solid; }
  .bb-block_language {
    margin-top: 10px;
    margin-bottom: 40px; }

.bb-block--paddings {
  padding-bottom: 47px; }
  .bb-block--paddings .PropertiesTitle {
    margin-bottom: 16px; }

.max-w-container {
  margin: 0 auto;
  max-width: 1360px; }

:root .pl-0 {
  padding-left: 0; }

:root .Error {
  font-size: 14px;
  color: #e83323;
  line-height: 1.4;
  padding-top: 20px;
  text-align: center; }
  :root .Error_textarea {
    padding-top: 5px;
    text-align: left; }

:root .Empty {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
  color: #8a97a0;
  font-size: 20px; }

