:root {
  .Select {
    margin-bottom: 24px;

    &_width {
      width: 246px;
    }

    &.Select_Error {
      .react-select__control {
        border-color: #e83323;
      }
    }

    &_Label {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      padding-bottom: 8px;
      letter-spacing: 0.5px;
    }
    // span {
    //   display: none;
    // }

    &_FakeBlock {
      display: flex;
      align-items: center;
      min-height: 38px;
      padding-left: 10px;
      border-radius: 3px;
      border: solid 1px #d8e4ea;
      background-color: #edf2f5;
      font-size: 14px;
      line-height: 1.71;
      color: #b7c2c9;
      position: relative;
    }

    &_InfoImg {
      width: 16px;
      height: 16px;
      object-fit: contain;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translate(0, -50%);
    }

    &_Tooltip {
      max-width: 136px;
      box-shadow: 0 2px 6px 0 rgba(38, 56, 66, 0.2);
      padding: 12px;
      background-color: #263842;
      line-height: normal;
      text-align: center;
      &:after {
        border-top-color: #263842 !important;
      }
    }
  }
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    font-size: 14px;
    color: #b7c2c9;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 98%;
  }

  .react-select__single-value {
    font-size: 14px;
  }
  .react-select__control {
    cursor: pointer;
    svg {
      fill: #7ecfa9;
    }
  }
}
