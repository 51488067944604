.spinner {
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  padding-top: 100px;
  &__small {
    width: 20px;
    height: 20px;
    &:after {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      margin: 1px;
      border-radius: 50%;
      border: 2px solid #fff;
      border-color: #1faeac transparent #1faeac transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

.spinner:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #1faeac transparent #1faeac transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
