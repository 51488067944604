@import '../../../assets/styles/parts';

:root {
  .Search {
    position: relative;

    &_Input {
      width: 100%;
      padding: 11px 120px 11px 12px;
      border: none;
      border-radius: 3px;
      font-size: 14px;
      outline: none;
      box-sizing: border-box;
    }

    &_Button {
      position: absolute;
      right: 5rem;
      top: 0;
      width: 70px;
      height: 100%;
      border: 0;
      outline: none;
      cursor: pointer;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      @extend %main-gradient;

      &-isFilter {
        right: 9rem;
        background: transparent;
      }

      img {
        opacity: 0.5;
      }
    }

    &-Badge {
      position: absolute;
      height: 8px;
      width: 8px;
      top: 6px;
      right: 22px;
      background: red;
      border-radius: 50%;
      pointer-events: none;
      z-index: 10;
    }
  }
}
