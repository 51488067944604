// Namespaces
// This stops me from being overzealous with enforcing classes
$grid-row-namespace: 'row' !default;
$grid-column-namespace: 'col' !default;

// $col-groups are the column groups you want
// For example, $col-groups: (3, 4, 5) will output:
// .col--n-of-3, .col--n-of-4, [...]
$col-groups: (5, 6, 8, 12) !default;

// Gutter width
$gutter-width: 40px !default;

.#{$grid-row-namespace} {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -$gutter-width;
  align-content: flex-start;

  // Centers the column in the grid and clears the row of all other columns
  &.#{$grid-row-namespace}--centered {
    align-items: center;
    justify-content: center;
  }

  &.#{$grid-row-namespace}--full {
    align-items: stretch;
    align-content: stretch;
  }

  &.#{$grid-row-namespace}--ac {
    align-items: baseline;
  }

  // Align column to the bottom.
  &.#{$grid-row-namespace}--ab {
    flex-direction: row;
    align-items: flex-end;
  }

  &.#{$grid-row-namespace}--as-col {
    flex-direction: column;
  }

  // Align column to the middle.
  &.#{$grid-row-namespace}--am {
    flex-direction: row;
    align-items: center;
  }

  &.#{$grid-row-namespace}--reverse {
    flex-direction: row-reverse;
  }

  &.#{$grid-row-namespace}--je {
    flex-direction: row;
    justify-content: flex-end;
  }

  &.#{$grid-row-namespace}--tr {
    text-align: right;
  }

  &.#{$grid-row-namespace}--sb {
    flex-direction: row;
    justify-content: space-between;
  }

  &.#{$grid-row-namespace}--no-wrap {
    flex-wrap: nowrap;
  }
  &.#{$grid-row-namespace}--wh-margin {
    margin-bottom: 2em;
  }
}

.#{$grid-column-namespace} {
  box-sizing: border-box;
  min-height: 1px;
  padding-left: $gutter-width;
  align-items: flex-start;
  flex: 1;

  &-tr {
    text-align: right;
  }

  &--wh-margin {
    margin-bottom: 2em;
  }

  // Displays the column as the first in its row
  &--first {
    order: -1;
  }

  // Displays the column as the last in its row
  &--last {
    order: 5;
  }

  &--order-1 {
    order: 1;
  }

  &--order-2 {
    order: 2;
  }

  &--order-3 {
    order: 3;
  }

  &--order-4 {
    order: 4;
  }

  &--auto {
    flex: 0 0 auto;
  }

  &--center {
    align-self: center;
  }

  &--bottom {
    align-self: flex-end;
  }
}

%span-all {
  flex: 0 1 percentage(1/1);
}

%one-half {
  flex: 0 1 percentage(1/2);
}

%one-third {
  flex: 0 1 percentage(1/3);
}

%two-thirds {
  flex: 0 1 percentage(2/3);
}

%one-quarter {
  flex: 0 1 percentage(1/4);
}

%two-quarters {
  flex: 0 1 percentage(2/4);
}

%three-quarters {
  flex: 0 1 percentage(3/4);
}

%push-span-all {
  margin-left: percentage(1/1);
}

// For each of our column groups...
@each $group in $col-groups {
  // For each column width from 1 to the column group...
  @for $i from 1 through $group {
    .#{$grid-column-namespace}--#{$i}-of-#{$group} {
      @if percentage($i/$group) == percentage(1/1) {
        @extend %span-all;
      } @else if percentage($i/$group) == percentage(1/2) {
        @extend %one-half;
      } @else if percentage($i/$group) == percentage(1/3) {
        @extend %one-third;
      } @else if percentage($i/$group) == percentage(2/3) {
        @extend %two-thirds;
      } @else if percentage($i/$group) == percentage(1/4) {
        @extend %one-quarter;
      } @else if percentage($i/$group) == percentage(2/4) {
        @extend %two-quarters;
      } @else if percentage($i/$group) == percentage(3/4) {
        @extend %three-quarters;
      } @else {
        flex: 0 1 percentage($i/$group);
      }
    }
  }
  // end @for
}

// end @each

// All direct descendents of .grid get treated the same way.
// This might be overkill for some, but it's a time-saver for me.

// container

.container {
  // padding: 0 60px;
  margin: 0 auto;
  width: 1360px;
  margin-top: 30px;
}

// Removes gutters from the columns
.#{$grid-row-namespace}--no-gutter {
  margin-left: 0;

  & > .#{$grid-column-namespace} {
    padding-left: 0;
  }
}

.#{$grid-row-namespace}--gutter-s {
  margin-left: -20px;

  & > .#{$grid-column-namespace} {
    padding-left: 20px;
  }
}

.pull-text--right {
  text-align: right;
}
