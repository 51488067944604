:root {
  .FilterForm {
    &_Groups {
      display: flex;
      justify-content: space-between;
    }

    &_Group {
      flex: 1 1 100%;
      margin-bottom: 8px;
      .RadioGroup_Header {
        margin-bottom: 16px;
      }
    }

    .FilterForm_Group ~ .FilterForm_Group {
      margin-left: 2.5rem;
    }

    &_Button {
      cursor: pointer;
      border: none;
      background-color: transparent;
      font-size: 14px;
      padding: 0;
    }

    &_cancel {
      color: #8a97a0;
      margin-right: 32px;
    }

    &_submit {
      color: #7ecfa9;
    }

    &_disabled {
      color: #8a97a0;
      cursor: not-allowed;
    }

    &_clear {
      font-size: 14px;
      color: #d0021b;
      cursor: pointer;
    }
  }
  .Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
  }
}
