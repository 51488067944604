:root {
  .RadioGroup {
    margin-bottom: 24px;
    cursor: pointer;
    &_Header {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &_Field {
      display: inline-flex;
      // align-items: center;
      & ~ .RadioGroup_Field {
        margin-left: 40px;
      }

      &.active {
        .RadioGroup_box {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-image: linear-gradient(135deg, #12abae, #88c69a);
          }
        }
      }
    }

    &_box {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: solid 2px #b7c2c9;
      background-color: #ffffff;
      display: inline-block;
    }

    &_Label {
      user-select: none;
      display: inline-block;
      font-size: 14px;
      // line-height: 1.5;
      height: 24px;
      line-height: 26px;
      cursor: pointer;
      position: relative;
      margin-left: 8px;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}
