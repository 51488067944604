@import "../../assets/styles/colors";

:root {
  .Footer {
    background-color: $main;
    // padding: 0 60px;
    letter-spacing: 0.5px;
    color: #8a97a0;
    &_cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
    }
  }
}
