@import './assets/styles/grid';
@import './assets/styles/parts';
@import './assets/styles/colors';
@import '~antd/lib/switch/style/index.css';
@import '~antd/lib/time-picker/style/index.css';
@import '~antd/lib/button/style/index.css';
@import '~antd/lib/input/style/index.css';

* {
  box-sizing: border-box;
}

.JTI_PWS_APP {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 500px;
  min-width: 1200px;
}

.error-notification {
  .ant-notification-notice-close {
    left: 28px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    * {
      display: none;
    }
  }
}

.Header {
  flex: 0 0 72px;
}

.Main {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;

  &_Content {
    flex: 1 1 auto;
  }
}

.Footer {
  flex: 0 0 64px;

  &_cont {
    max-width: 1360px;
    margin: 0 auto;
  }
}

.Button {
  height: 40px;
  padding: 0 16px;
  line-height: 40px;
  display: inline-block;
  color: #263842;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  @extend %main-border-gradient;

  &-isWhite {
    background: transparent;
    border-color: white;
    color: white;
    border-image-source: none;
  }

  &-withoutBorder {
    border-image-source: none;
    border-color: transparent;
  }

  &.Button-withMarginRight {
    margin-right: 20px;
  }

  &.Button-withBackground {
    color: white;
    @extend %main-gradient;
  }
  &:disabled {
    background: #d8e4ea !important;
    color: #b7c2c9 !important;
  }
}

.Person {
  &_Id {
    color: $grey;
    padding-right: 6px;
    font-size: 14px;
  }

  &_Team {
    font-size: 14px;
    border: 1px solid $green;
    border-radius: 15px;
    padding: 5px 13px;
    color: $green;
    display: inline-block;
  }

  &_Name {
    font-size: 32px;
    color: $green;
  }

  &_Supervisor {
    color: $green;
  }
  &_placeIcon {
    width: 24px;
    margin-right: 10px;
  }
  &_geolocation {
    display: inline-flex;
    align-items: center;
    margin: 16px 0;
    span {
      color: #8a97a0;
    }
  }
}

.Link {
  cursor: pointer;
  user-select: none;

  &-withColor {
    &,
    &:visited,
    &:active,
    &:hover {
      color: #7ecfa9;
    }
  }

  &-withIcon {
    display: inline-flex;
    line-height: 24px;

    // margin-right: 1em;
    &:not(:last-child):after {
      content: '';
      height: 24px;
      width: 1px;
      background-color: #59bba1;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}

.with-shadow {
  padding-bottom: 16px;
  box-shadow: 0 3px 3px 0 rgba(68, 100, 117, 0.18);
}

.bb-block {
  height: auto;
  padding-bottom: 1em;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  @extend %main-border-gradient;
  &_language {
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

.bb-block--paddings {
  padding-bottom: 47px;
  .PropertiesTitle {
    margin-bottom: 16px;
  }
}

.max-w-container {
  margin: 0 auto;
  max-width: 1360px;
}

:root {
  .pl-0 {
    padding-left: 0;
  }
  .Error {
    font-size: 14px;
    color: #e83323;
    line-height: 1.4;
    padding-top: 20px;
    text-align: center;
    &_textarea {
      padding-top: 5px;
      text-align: left;
    }
  }

  .Empty {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    color: #8a97a0;
    font-size: 20px;
  }
}
