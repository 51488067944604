.Popup {
  position: fixed;
  z-index: 150;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  &-Bg {
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0.75;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, #121b21, #121b21);
  }

  &-Close {
    position: absolute;
    z-index: 6;
    background: transparent;
    padding: 0;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    border: 0;
    min-width: 1px;
    outline: none;
    cursor: pointer;

    &:before,
    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 2px;
      height: 24px;
      margin-top: -12px;
      margin-left: -1px;
      background-image: linear-gradient(103deg, #12abae, #88c69a);
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  &-Wrapper {
    height: 100vh;
    overflow: auto;
  }

  &-Content {
    display: table;
    height: 100%;
    max-width: 475px;
    width: 100%;
    margin: 0 auto;
  }

  &-Inner {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 0;
  }

  &-Block {
    position: relative;
    z-index: 10;
    border-radius: 3px;
    box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    padding: 63px 30px 50px;
    text-align: center;
  }

  &-Icon {
    margin-bottom: 16px;

    img {
      max-width: 100%;
    }
  }

  &-Title {
    font-family: Cambay;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.1;
    margin-bottom: 9px;
    color: #263842;
  }

  &-Text {
    line-height: 1.5;
    color: #8a97a0;
  }

  &-Bottom {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.Center {
      justify-content: center;
    }
  }

  &-Btn {
    font-size: 14px;
    padding: 16px 15px;
    font-weight: 700;
    text-transform: uppercase;
    width: 48%;

    &.Btn {
      font-size: 14px;
      padding: 16px 15px;
    }
  }

  // Popup Archive Info
  &-InfoArchive {
    max-width: 875px;

    &-Content {
      position: relative;
      z-index: 5;
    }

    &-Count {
      text-align: center;
      position: relative;
      z-index: 5;
      font-size: 24px;
      line-height: 1;
      color: #edf2f5;
      padding-bottom: 30px;
    }

    .Popup-Block {
      padding: 21px 25px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-radius: 0 0 3px 3px;
    }

    &-Img {
      border-radius: 3px 3px 0 0;
      width: 100%;
      height: 515px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: #fff;
    }

    &-Left {
      text-align: left;
      width: 60%;
    }

    &-Right {
      width: 40%;
      text-align: right;

      .Btn {
        margin: 0 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-Title {
      font-family: Cambay;
      font-size: 32px;
      font-weight: normal;
      line-height: 1.1;
      color: #55b58a;
    }

    &-Item {
      padding: 10px 0 5px;
      line-height: 1.25;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-size: 14px;
    }

    &-Name {
      color: #8a97a0;
      padding: 10px 0;
      span {
        color: #263842;
        padding-left: 15px;
      }
    }

    &-Btn {
      outline: none;
      cursor: pointer;
      width: 56px;
      z-index: 6;
      height: 56px;
      padding: 0;
      position: absolute;
      top: 50%;
      margin-top: -28px;
      border: 1px solid #d8e4ea;
      background: transparent;

      &:hover {
        border-style: solid;
        border-color: #55b58a;
        border-width: 1px;
        border-image-source: linear-gradient(135deg, #12abae, #88c69a);
        border-image-slice: 1;
        background: transparent;

        svg path {
          fill: #55b58a;
        }
      }

      &[disabled] {
        border: 1px solid #d8e4ea;
        background: transparent;
        cursor: no-drop;

        svg path {
          fill: #d8e4ea;
        }
      }

      &.Prev {
        left: -180px;
      }

      &.Next {
        right: -180px;

        span {
          transform: rotate(180deg);
        }
      }

      span {
        display: block;
        width: 28px;
        margin: -14px 0 0 -14px;
        top: 50%;
        left: 50%;
        position: absolute;

        svg {
          display: inline-block;
          vertical-align: middle;

          path {
            fill: #d8e4ea;
          }
        }
      }
    }
  }
}
