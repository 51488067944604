@import './colors';

%main-gradient {
  background: linear-gradient(119deg, #12abae, #88c69a);
}

%button {
  border: none;
  display: block;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  cursor: pointer;
  letter-spacing: 0.5px;
  text-align: center;
}

%button:disabled,
%button[disabled] {
  background: #d8e4ea;
  color: #b7c2c9;
}

%main-border-gradient {
  border-image-source: linear-gradient(104deg, #12abae, #88c69a);
  border-image-slice: 1;
}

.Wrapper {
  width: 100%;
  max-width: 1350px;
  padding: 0 15px;
  min-width: 1180px;
  margin: 0 auto;
}

// Button
.Btn {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.1;
  background: transparent;
  border: 0;
  position: relative;
  cursor: pointer;
  padding: 11px 15px;
  outline: none;
  font-family: Roboto;

  &.Btn-Green {
    span {
      color: #fff;
    }

    &:before {
      content: none;
    }
  }

  &[disabled] {
    cursor: no-drop;

    span {
      color: #b7c2c9;
    }

    &:after,
    &:before {
      background: #d8e4ea;
    }
  }

  &:before,
  &:after {
    transition: all 0.3s;
    content: '';
    position: absolute;
    display: block;
    border-radius: 3px;
  }

  &:after {
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(105deg, #12abae, #88c69a);
  }

  &:before {
    background: #fff;
    left: 1px;
    right: 1px;
    bottom: 1px;
    top: 1px;
    z-index: 2;
  }

  span {
    position: relative;
    z-index: 3;
    color: #7ecfa9;
  }

  &-Text {
    padding: 0;
    background: transparent;
    border: 0;
    position: relative;
    cursor: pointer;
    outline: none;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.1;
    color: #7ecfa9;
  }

  &.WithIcon {
    padding-left: 48px;

    //span.WithIcon-Img,
    .WithIcon-Img {
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translateY(-50%);
      svg {
        display: block;
      }
    }
  }
}
