@import "../../assets/styles/colors";

:root {
  .Header {
    font-size: 12px;
    color: white;
    background-color: $main;
    transition: all 0.3s;
    // overflow: hidden;
    position: relative;

    &_Caption {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 72px;
      // padding: 0 60px;
      max-width: 1360px;
      margin: 0 auto;
    }

    &.Header-isOpen {
      flex: 0 0 184px;
      .MainMenu {
        //overflow: visible;
        //height: 115px;
      }
    }
    &.Header-isFilterOpen {
      // overflow: visible;
      position: relative;
    }

    .Logo {
      float: left;

      &_Slogan {
        margin: 4px 15px 0;
        width: 12em;
        float: right;
      }
    }

    &_Search {
      flex: 1 1 30%;
      padding: 0 5rem;
    }
  }
}
