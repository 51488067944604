.notification-success {
  position: absolute;
  top: 80px;
  left: 0;
  height: 50px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: white;
  line-height: 50px;
  background-color: rgba($color: #06b085, $alpha: 0.85);
  button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    width: 16px;
    height: 16px;
  }
  img {
    width: 13px;
    height: 13px;
  }
}
