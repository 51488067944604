:root {
  .User {
    margin-top: -7px;
    font-size: 14px;

    &_Icon {
      margin-right: 0.5em;
      transform: translateY(25%);
    }
    &_Position {
      color: #8a97a0;
      margin-left: 10px;
    }
  }

  .Notification {
    padding: 0 0 0 16px;
    position: relative;

    &_counter {
      &:after {
        content: attr(data-count);
        position: absolute;
        height: 16px;
        width: 16px;
        line-height: 16px;
        top: -0.9em;
        right: -10px;
        background: red;
        border-radius: 50%;
        text-align: center;
        font-size: 10px;
      }
    }
  }
}
