:root {
  .MainMenu {
    display: flex;
    justify-items: stretch;
    border-top: 1px solid #446475;
    // padding: 0 60px;
    color: #8a97a0;
    font-size: 16px;
    margin: 0 auto;
    max-width: 1360px;
    transition: all 0.2s;
    //height: 0;
    overflow: hidden;
    animation: menu-visible 0.3s;

    &_Item {
      flex: 1 1 100%;
      height: 112px;
      text-align: center;
      padding: 16px 5px;
      box-sizing: border-box;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .MenuItem {
      &-isActive {
        background-color: #446475;
        // overflow: visible;
        svg {
          path {
            fill: white !important;
          }
        }
        .MenuItem_Text {
          color: white;
        }
      }

      &_Image {
        cursor: pointer;
        width: 100%;
        max-width: 52px;
        margin: 0 auto;
        display: inline-block;

        svg {
          display: inline-block;
          vertical-align: middle;
        }
      }

      &_Text {
        margin-top: 9px;
      }
    }
  }
}

@keyframes menu-visible {
  from {
    opacity: 0;
    min-height: 0;
    height: 0;
  }
  to {
    min-height: 15px;
    height: auto;
  }
}
