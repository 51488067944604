:root {
  .SelectRange {
    margin-bottom: 24px;

    &_Label {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      padding-bottom: 8px;
      letter-spacing: 0.5px;
    }

    &_Sections {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_Separator {
      width: 1em;
      height: 1px;
      background-color: #d8e4ea;
      margin: 0 5px;
      flex: 0 0 1em;
    }

    &_Section {
      flex: 1 1 auto;
    }
  }
}
