:root {
  .SearchFilter {
    position: absolute;
    top: 72px;
    padding: 20px 8%;
    width: 100%;
    color: black;
    background-color: white;
    z-index: 999;
    box-shadow: 0 4px 14px 0 rgba(138, 151, 160, 0.3);
    box-sizing: border-box;

    &_Caption {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;
      width: 100%;
    }

    &_Header {
      font-size: 14px;
      color: #263842;
      font-weight: 500;
    }

    &_Close {
      border: none;
      background-color: transparent;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
